import React from 'react';
import Footer from "./Footer";

const ReleasePage = () => {
  return (
    <div className="doc-page">
      <div>

        <h1>Release log</h1>

        <h4>3.4.0 - 19th Sep 2024</h4>
        <ul className="eppic-iframe-log-list">
          <li>New react-based frontend application</li>
          <li>Rewrite of REST service as a Spring app with a MongoDB backend</li>
        </ul>

        <h4>3.3.10 - 28th September 2022</h4>
        <ul className="eppic-iframe-log-list">
          <li>Replaced UniProt JAPI by UniProt proteins REST API</li>
        </ul>

        <h4>3.3.9 - 26th February 2022</h4>
        <ul className="eppic-iframe-log-list">
          <li>Upgrade to biojava-6.0.5</li>
        </ul>

        <h4>3.3.8 - 26th February 2022</h4>
        <ul className="eppic-iframe-log-list">
          <li>Upgrade of log4j (log4shell)</li>
        </ul>

        <h4>3.3.7 - 21st December 2021</h4>
        <ul className="eppic-iframe-log-list">
          <li>Upgrade of log4j (log4shell)</li>
        </ul>

        <h4>3.3.6 - 14th December 2021</h4>
        <ul className="eppic-iframe-log-list">
          <li>Upgrade of log4j (log4shell)</li>
        </ul>

        <h4>3.3.5 - 13th December 2021</h4>
        <ul className="eppic-iframe-log-list">
          <li>Upgrade log4j to 2.15.0 to avoid log4shell vulnerability</li>
          <li>Upgrade to biojava-6.0.2</li>
        </ul>

        <h4>3.3.4 - 8th September 2021</h4>
        <ul className="eppic-iframe-log-list">
          <li>https-related fixes</li>
        </ul>

        <h4>3.3.3 - 20th July 2021</h4>
        <ul className="eppic-iframe-log-list">
          <li>Upgrade to biojava-6.0.0-alpha4</li>
        </ul>

        <h4>3.3.2 - 14th April 2021</h4>
        <ul className="eppic-iframe-log-list">
          <li>Upgrade to biojava-6.0.0-alpha3</li>
          <li>Upgrade to latest UniProt japi</li>
        </ul>

        <h4>3.3.1 - 11th March 2021</h4>
        <ul className="eppic-iframe-log-list">
          <li>Upgrade to biojava-6.0.0-alpha2</li>
          <li>Upgrade to latest UniProt japi</li>
        </ul>

        <h4>3.3.0 - 4th February 2021</h4>
        <ul className="eppic-iframe-log-list">
          <li>Changed data types for residueburial data model. Limit of int reached for uid.</li>
        </ul>

        <h4>3.2.9 - 22nd January 2021</h4>
        <ul className="eppic-iframe-log-list">
          <li>Upgrade to latest UniProt japi</li>
        </ul>

        <h4>3.2.8 - 9th October 2020</h4>
        <ul className="eppic-iframe-log-list">
          <li>Upgrade to latest UniProt japi</li>
        </ul>

        <h4>3.2.7 - 25th July 2020</h4>
        <ul className="eppic-iframe-log-list">
          <li>Upgrade to latest BioJava</li>
        </ul>

        <h4>3.2.6 - 22nd June 2020</h4>
        <ul className="eppic-iframe-log-list">
          <li>Upgrade to latest UniProt japi</li>
        </ul>

        <h4>3.2.5 - 2nd May 2020</h4>
        <ul className="eppic-iframe-log-list">
          <li>Upgrade to latest UniProt japi</li>
        </ul>

        <h4>3.2.4 - 20th December 2019</h4>
        <ul className="eppic-iframe-log-list">
          <li>Upgrade to latest UniProt japi</li>
        </ul>

        <h4>3.2.3 - 30th September 2019</h4>
        <ul className="eppic-iframe-log-list">
          <li>Bugfix: 3D lattice graph view now works again</li>
          <li>Library upgrades: BioJava and UniProt japi</li>
          <li>Some other bug fixes</li>
        </ul>

        <h4>3.2.2 - 27th August 2019</h4>
        <ul className="eppic-iframe-log-list">
          <li>Better server job management</li>
          <li>Library upgrades</li>
          <li>Bug fixes</li>
        </ul>

        <h4>3.2.1 - 14th July 2019</h4>
        <ul className="eppic-iframe-log-list">
          <li>Better REST API docs</li>
          <li>A few bug fixes</li>
        </ul>

        <h4>3.2.0 - 5th February 2019</h4>
        <ul className="eppic-iframe-log-list">
          <li>
            Precomputation now uses <a href="https://github.com/soedinglab/MMseqs2" target="_blank"
                                       rel="noreferrer">mmseqs2</a> for much faster sequence search. User jobs still
            rely on blastp.
          </li>
          <li>New behaviour: a single redundancy reduction step (clustering at 90% sequence identity) is performed on
            sequence homologs for MSA
          </li>
          <li>BioJava dependency updated to next release 5.2.1. Includes performance improvements for ASA calculation
          </li>
          <li>Thumbnail creation now works for large viral capsid entries</li>
          <li>No evolutionary analysis is performed anymore for protein chains with length &lt;=30 residues</li>
          <li>Some bug fixes.</li>
        </ul>

        <h4>3.1.0 - 29th August 2018</h4>
        <ul className="eppic-iframe-log-list">
          <li>New feature: REST API for more fine grained data downloading. All graph data including assembly symmetry
            operators is now available through API.
          </li>
          <li>BioJava dependency updated to next release 5.1.0.</li>
          <li>Many bug fixes.</li>
        </ul>

        <h4>3.0.6 - 11th May 2018</h4>
        <ul className="eppic-iframe-log-list">
          <li>BioJava dependency updated to next bugfix release 5.0.1.</li>
          <li>PDB files with 1 chain should work now. Bug introduced in 3.0.5</li>
        </ul>

        <h4>3.0.5 - 1st May 2018</h4>
        <ul className="eppic-iframe-log-list">
          <li>BioJava dependency updated to next major release 5.0.0.</li>
          <li>Redundancy in interfaces now handled better for virus capsid entries.</li>
          <li>Bugfix: PDB files with water-only chains should run correctly now</li>
          <li>Some bug fixes.</li>
        </ul>

        <h4>3.0.4 - 9th February 2018</h4>
        <ul className="eppic-iframe-log-list">
          <li>Assembly probabilities are now normalised.</li>
          <li>Structures with many clashes in any interface are now fully computed, showing warnings for the clashes.
          </li>
          <li>Fixed CLI issue, UniProt JAPI connection wasn't working.</li>
          <li>Some bug fixes.</li>
        </ul>

        <h4>3.0.3 - 19th October 2017</h4>
        <ul className="eppic-iframe-log-list">
          <li>BioJava dependency updated to latest bugfix version 4.2.9</li>
          <li>Fixed problems where CLI was crashing in assembly creation in some edge cases.</li>
          <li>NGL now displays all subassemblies of disjoint assemblies.</li>
          <li>A few bug fixes</li>
        </ul>

        <h4>3.0.2 - 18th September 2017</h4>
        <ul className="eppic-iframe-log-list">
          <li>BioJava dependency updated to latest bugfix version 4.2.8</li>
          <li>A few bug fixes</li>
        </ul>

        <h4>3.0.1 - 21st April 2017</h4>
        <ul className="eppic-iframe-log-list">
          <li>Assembly probability scores now shown in assemblies table. Default sorting by probability.</li>
          <li>Fixed issue with large heteromeric assemblies not being described correctly.</li>
          <li>Known issues: DNA/RNA-only assemblies are not called correctly, disjoint assemblies are not shown
            correctly in thumbnails and 3D view.
          </li>
        </ul>

        <h4>3.0.0 - 16th March 2017</h4>
        <ul className="eppic-iframe-log-list">
          <li>Full assembly enumeration and prediction of most likely assembly</li>
          <li>Probabilistic scoring of interfaces and assemblies, including confidence assignments.</li>
          <li>2D and 3D visualizations of the lattice graph</li>
          <li>WebGL JavaScript viewer NGL</li>
          <li>Surface sequence entropy 3D visualizations in browser, no need of PSE files</li>
          <li>Based on BioJava 4.2, replacing OWL</li>
          <li>NCS operators now correctly used to build viral capsid structures</li>
          <li>Large structures with multiletter chain ids now supported</li>
          <li>PDB biounit assignments now displayed side-by-side with assembly predictions</li>
          <li>Interface clustering now based on contact similarity instead of RMSD</li>
          <li>Known issues: DNA/RNA-only assemblies are not called correctly, large heteromeric assemblies don't work
            correctly.
          </li>
        </ul>

        <h4>2.1.2 - 19th November 2014</h4>
        <ul className="eppic-iframe-log-list">
          <li>XML files now have residue details</li>
          <li>No similar structure search button will appear if sequence clusters are empty for the chain</li>
          <li>Source code unified into a single repository with submodules</li>
          <li>GXT updated to 3.1.1</li>
          <li>Some bug fixes</li>
        </ul>

        <h4>2.1.1 - 21st August 2014</h4>
        <ul className="eppic-iframe-log-list">
          <li>Downloadable command-line program is now correctly packaged</li>
          <li>New database statistics page</li>
          <li>Similar structures search is now a lot faster</li>
          <li>Some bug fixes</li>
        </ul>

        <h4>2.1.0 - 8th July 2014</h4>
        <ul className="eppic-iframe-log-list">
          <li>Jmol will launch now in JS mode if Java is not detected</li>
          <li>Predictions now also done at interface cluster level by averaging within cluster</li>
          <li>XML data downloads</li>
          <li>New feature: searching similar structures from sequence clusters</li>
          <li>Disulfide bonds are no longer used as a bio call criterium</li>
          <li>Complete redesign of data model behind the scenes</li>
          <li>Many bug fixes</li>
        </ul>

        <h4>2.0.5 - 31st January 2014</h4>
        <ul className="eppic-iframe-log-list">
          <li>Now Phenix mmCIF files will be properly parsed</li>
          <li>Jmol version updated to 13.0.18</li>
          <li>Better warnings for low resolution, high R-free and EM structures</li>
          <li>Problems with alignment window are now solved in IE</li>
          <li>A few bug fixes</li>
        </ul>

        <h4>2.0.4 - 17th January 2014</h4>
        <ul className="eppic-iframe-log-list">
          <li>Major user interface change: upgraded to GXT 3.0.1, new top navigation bar, improved "My Jobs" panel,
            nicer fonts and much more
          </li>
          <li>New homologs information pop-up window providing details on the identity distribution and taxonomy of each
            homolog used in the MSA
          </li>
          <li>Introduced interface clustering. A button on top of the interfaces table now triggers a cluster view of
            equivalent interfaces
          </li>
          <li>New FAQ and Publications pages. Improved and expanded Help page</li>
        </ul>

        <h4>2.0.3 - 27th November 2013</h4>
        <ul className="eppic-iframe-log-list">
          <li>Improvements in top panel: now split into experimental info panel and sequence info panel. Also some
            improvements in residue details window: scores are now better displayed
          </li>
          <li>Cleaned up the input page: only alignment parameters can now be modified in advanced input</li>
          <li>Issues with windows and tooltips displaying badly in IE are now gone thanks to GXT 2.3</li>
          <li>Warnings for low resolution or high R-free added</li>
          <li>Interfaces below 35 Å<sup>2</sup> are not displayed anymore</li>
          <li>Fixed issue in interfaces calculation: the interface search extends now to more distant cells, missing
            interfaces in structures such as 1was will now appear
          </li>
          <li>A few bug fixes</li>
        </ul>

        <h4>2.0.2 - 22nd August 2013</h4>
        <ul className="eppic-iframe-log-list">
          <li>Precomputed EPPIC results are now available for the whole PDB, after typing a PDB code the results will
            appear immediately
          </li>
          <li>Now operators displayed as symmetry icons with the algebraic operators in tooltips. Operators colored in
            red to mark infinite interfaces
          </li>
          <li>A few bug fixes. In particular issue with crashes in parsing PDB files having no TER records (or badly
            placed ones) is now fixed
          </li>
          <li>The static pages are now fully crawlable by search engines</li>
          <li>Some style improvements</li>
        </ul>

        <h4>2.0.1 - 19th April 2013</h4>
        <ul className="eppic-iframe-log-list">
          <li>Fixed displaying problems in residue details table, the ugly white frames disappeared</li>
          <li>Now the homologs panel in top panel contains links to download the colored-by-entropies PSE files produced
            by the EPPIC command line program. Very useful to get a visual impression of the conservation of residues at
            the surface of the structure. This shows graphically why EPPIC works
          </li>
          <li>Better handling of UniProt reference mapping, especially for multi-segment mappings</li>
          <li>More warnings added: unreliability of surface residues (surface residues not aligning to UniProt
            reference), if too many then NOPRED call is given
          </li>
          <li>Display and style improvements</li>
          <li>A few bug fixes</li>
        </ul>

        <h4>2.0.0 - 15th February 2013</h4>
        <ul className="eppic-iframe-log-list">
          <li>First full public release</li>
          <li>Cofactors of more than 40 non-Hydrogen atoms are now taken into account. They will be considered as part
            of the protomers in ASA calculations. This affects especially predictions in small proteins with large
            cofactors like hemoglobin
          </li>
          <li>PDB residue numbers are now used for warning messages and output jmol, pdb and pse files</li>
          <li>The missing eppic.conf file is now provided in downloadable eppic.zip</li>
          <li>The guessing of file types is now more flexible, it will allow PDB files starting with: HEADER, TITLE,
            COMPND, SOURCE, EXPDTA, REMARK, DBREF, SEQRES, CRYST1, MODEL, HELIX, SHEET. Any other file not starting with
            one of these lines will be rejected
          </li>
          <li>Blast results are now precomputed for all PDB entries which makes runs for deposited PDBs a lot faster
          </li>
          <li>The web session will now expire after 24 hours of inactivity. The "My Jobs" list will then be lost</li>
          <li>Style improvements</li>
          <li>Many bug fixes, especially in relation to parsing PDB and mmCIF files</li>
        </ul>

        <h4>1.9.6 - 22nd December 2012</h4>
        <ul className="eppic-iframe-log-list">
          <li>First public pre-release</li>
        </ul>
      </div>
      <Footer fixbottom={false}/>
    </div>
  );
};

export default ReleasePage;