import React, {Component} from 'react';
import Table from 'react-bootstrap/Table';
import '../App.css';
import * as constants from '../Data';
import {BoxArrowDown, ExclamationTriangle} from "react-bootstrap-icons";
import {Button, Tooltip} from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

export class SequenceTable extends Component {
    constructor(props) {
        super(props);
        this.state = {sequences: null, pdbId: null}
        this.loadSequence = this.loadSequence.bind(this)
    }

    downloadMsaFile = (repChain) => {
        // recipe from https://stackoverflow.com/questions/44656610/download-a-string-as-txt-file-in-react
        const element = document.createElement("a");
        const msaString = constants.seqDataToMsaFasta(this.state.sequences, repChain);
        const file = new Blob([msaString], {type: 'text/plain'});
        element.href = URL.createObjectURL(file);
        element.download = `${this.state.pdbId}-${repChain}.fasta`;
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
    }

    /**
     * fetch sequence data from EPPIC sequence endpoint, update state respectively
     */
    loadSequence() {
        if (this.props.pdbId != null) {
            console.log("Fetching sequence data for " + this.props.pdbId);
            fetch(this.props.clientConfigs.eppicApiServerBaseUrl + constants.SEQUENCES_END_POINT + this.props.pdbId)
                .then((response) => {
                    if (!response.ok) {
                        throw new Error("Fail to fetch");
                    } else {
                        return response.json();
                    }
                })
                .then((data) => {
                    const proteinData = data.filter(sequence => sequence.protein)
                    this.setState({sequences: proteinData, pdbId: this.props.pdbId})
                })
                .catch(console.log)
        }
    }

    formatUniProtWarning = (uniProtRefWarnings) => {
        let warnStr = '';
        uniProtRefWarnings.forEach((w) => {
            warnStr += w.text + '\n';
        });
        return warnStr;
    }

    render() {
        if (this.props.pdbId == null) {
            return null;
        }

        if (this.state.pdbId !== this.props.pdbId) {
            this.loadSequence();
            return null;
        }

        const Sequences = ({sequences}) => {
            if (sequences != null) {
                return (
                    <tbody>
                    {sequences.map((sequence) => (
                        <tr key={sequence.repChain}>
                            <td className="align-middle">{sequence.repChain}</td>
                            <td className="align-middle">{sequence.memberChains}</td>
                            <td className="align-middle">
                                {sequence.hasUniProtRef?
                                <a href={constants.getUniprotUrl(sequence.refUniProtId)} target="_blank" rel="noreferrer">{sequence.refUniProtId}</a>
                                    :
                                    sequence.uniProtRefWarnings &&
                                    <OverlayTrigger placement="right" overlay={<Tooltip id="op-tooltip">{this.formatUniProtWarning(sequence.uniProtRefWarnings)}</Tooltip>}>
                                        <ExclamationTriangle width='22' height='22'></ExclamationTriangle>
                                    </OverlayTrigger>
                                }
                            </td>
                            <td className="align-middle">
                                {sequence.numHomologs > 0 ?
                                    <Button variant='outline-primary' onClick={() => this.downloadMsaFile(sequence.repChain)}>
                                        <BoxArrowDown></BoxArrowDown>
                                        &nbsp; Download MSA ({sequence.numHomologs} homologs)
                                    </Button>
                                    :
                                    <div></div>
                                }
                            </td>
                        </tr>
                    ))}
                    </tbody>
                )
            } else {
                return (
                    <tbody>
                    </tbody>);
            }
        };

        return (
            <Table striped bordered hover>
                <thead>
                <tr>
                    <th>Representative chain ID</th>
                    <th>Member chain IDs</th>
                    <th>Reference UniProt ID</th>
                    <th>Multiple Sequence Alignment</th>
                </tr>
                </thead>

                <Sequences sequences={this.state.sequences}/>

            </Table>
        )
    }


}