import '../App.css';
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import Row from "react-bootstrap/Row";
import React from "react";

export const ErrorPanel = ({ error }) => {

    return (
        <div style={{ paddingTop: 20 }}>
            <Row>
                <Col xs={5}>
                    <Alert variant="warning">
                        <p>Error: {error.message}</p>
                        {/* this is the handling for a 404 TODO handle other cases when we know what the cause is */}
                        {error.pdbId.length === 4?
                            <p>Please note that precomputed data is only available for PDB entries from crystallographic methods</p>
                            :
                            <p>Please note that user jobs are stored in the server only for 30 days</p>
                        }
                    </Alert>
                </Col>
            </Row>
        </div>
    );
};