import React, { useState, useEffect } from 'react';
import {BrowserRouter as Router, Link, Route, Routes, useLocation, useNavigate, useParams} from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import {XSquareFill} from 'react-bootstrap-icons';
import * as constants from './Data';
import { LeftNav } from './ui/LeftNav';
import { PdbInfoPanel } from "./ui/PdbInfoPanel";
import { AssembliesTable } from "./ui/AssemblyTable";
import { InterfTable } from "./ui/InterfaceTable";
import { SequenceTable } from "./ui/SequenceTable";
import SelectionDropdown from "./ui/SelectionDropdown";
import './App.css';
import HelpPage from "./ui/HelpPage";
import TopNav from './ui/TopNav';
import FaqPage from './ui/FaqPage';
import DownloadPage from './ui/DownloadPage';
import ReleasePage from './ui/ReleasePage';
import PublicationPage from './ui/PublicationPage';
import {Spinner, Tooltip} from "react-bootstrap";
import {ErrorPanel} from "./ui/ErrorPanel";
import {NotFound} from "./ui/NotFound";
import {RedirectPage} from "./ui/RedirectPage";
import Alert from "react-bootstrap/Alert";
import {JobStatus} from "./Data";


function App() {
    const [state, setState] = useState({
        pdbId: null,
        pdbInfo: null,
        tab: constants.TAB_ASSEMBLIES,
        interfaceClusterIdsToFilter: null,
        currAssembly: null,
        jobIds: [],
        clientConfigs: null,
        error: null
    });

    useEffect(() => {
        // init configurations by reading them from server config endpoint
        initClientConfigs();
    }, []);

    const initClientConfigs = () => {
        const defaultClientConfigs = {eppicApiServerBaseUrl: "https://eppic-rest-east.rcsb.org", skipEvolAnalysis: true};
        fetch("/clientConfig.json")
            .then((response) => {
                if (!response.ok) {
                    console.log("Fail to fetch client config from server. This should only happen in dev mode. Will default to " + defaultClientConfigs);
                    return defaultClientConfigs;
                } else {
                    return response.json();
                }
            }).then((data) => {
            console.log("Got configuration data from server: " + JSON.stringify(data));
            setState(prevState => ({ ...prevState, clientConfigs: data}));
        }).catch(console.log);
    }

    const handlePdbIdChange = (pdbId, tab = constants.TAB_ASSEMBLIES, eppicApiServerBaseUrl) => {
        if (!pdbId || pdbId.trim() === '' || pdbId === 'null') {
            console.error("Invalid PDB ID");
            return;
        }

        if (state.pdbId === pdbId) {
            // If the PDB ID hasn't changed, just update the tab
            setState(prevState => ({ ...prevState, tab: tab }));
            return;
        }

        const jobObj = state.jobIds.find(jobIdObj => jobIdObj.id === pdbId);
        if (jobObj && jobObj.jobStatus === JobStatus.ERROR) {
            // TODO pass the error from the API, when API does it
            setState(prevState => ({...prevState, pdbId: pdbId, error: {pdbId: pdbId, message: "Error processing uploaded file"}}))
            return;
        }

        if (jobObj && constants.isJobStatusProcessing(jobObj.jobStatus)) {
            // do not try and fetch PDBInfo if job is currently processing
            setState(prevState => ({...prevState, pdbId: pdbId, error: null}))
            return;
        }

        // This call is done at this level (instead of doing it at PdbInfoPanel) so that we can know ASAP if
        // an entry is not present in API and show an error message. We also grab the UniProt version to display in
        // tab from this call
        console.log(`Fetching PdbInfo data for ${pdbId}`)
        fetch(eppicApiServerBaseUrl + constants.PDBINFO_END_POINT + pdbId)
            .then((response) => {
                if (!response.ok) {
                    if (response.status === 404) {
                        throw new Error("Could not find data for id " + pdbId);
                    } else {
                        throw new Error("Something went wrong when retrieving data for " + pdbId + ". Please try again later.");
                    }
                }
                return response.json();
            })
            .then((data) => {
                setState(prevState => {
                    return {
                        ...prevState,
                        pdbId: pdbId,
                        tab: tab,
                        pdbInfo: data,
                        // for case when pdbId was already in loaded ids (left nav) then we don't modify it
                        jobIds: prevState.jobIds.some(j => j.id === pdbId)? prevState.jobIds: [...prevState.jobIds, {id: pdbId, jobStatus: constants.JobStatus.FINISHED}],
                        interfaceClusterIdsToFilter: null,
                        currAssembly: null,
                        error: null
                    };
                });
            })
            .catch((error) => {
                if (constants.isUserJobId(pdbId) && constants.isJobProcessing(eppicApiServerBaseUrl, pdbId)) {
                    console.log(`Job id ${pdbId} wasn't found in server (error: ${error}), but it looks like a user job and is known to the server's status endpoint. Considering it is processing`);
                    setState(prevState => {
                        return {
                            ...prevState,
                            pdbId: pdbId,
                            tab: tab,
                            jobIds: [...prevState.jobIds, {id: pdbId, jobStatus: constants.JobStatus.WAITING}],
                            error: null
                        };
                    });
                } else {
                    console.error(`Error fetching PDBInfo data for id ${pdbId}. Error:`, error);
                    setState(prevState => {
                        return {
                            ...prevState,
                            error: {pdbId: pdbId, message: error.message}
                        };
                    });
                }
            });
    }

    const handleReset = () => {
        setState(prevState => ({
            ...prevState,
            interfaceClusterIdsToFilter: null,
            currAssembly: null
        }));
    }

    const handleFilter = (filter) => {
        setState(prevState => ({
            ...prevState,
            interfaceClusterIdsToFilter: filter
        }));
    }

    const handleSelect = (key, assembly) => {
        if (Number.isInteger(assembly)) {
            setState(prevState => ({
                ...prevState,
                currAssembly: assembly
            }));
        }
        setState(prevState => ({
            ...prevState,
            tab: key
        }));
    }

    // Function to switch processing of an id to FINISHED
    const handleProcessingFinishedForId = (id) => {
        setState(prevState => ({
            ...prevState, jobIds: prevState.jobIds.map(jobIdObj => (jobIdObj.id === id)? {id: id, jobStatus: constants.JobStatus.FINISHED}:jobIdObj)
        }))
    };

    const handleProcessingErrorForId = (id) => {
        // TODO pass the error message from the API, when API does it
        setState(prevState => ({
            ...prevState,
            jobIds: prevState.jobIds.map(jobIdObj => (jobIdObj.id === id)? {id: id, jobStatus: constants.JobStatus.ERROR}:jobIdObj),
            error: {pdbId: id, message: "Error processing uploaded file"}}))
    }

    return (
        <Router>
            <AppContent
                state={state} 
                handlePdbIdChange={handlePdbIdChange} 
                handleReset={handleReset} 
                handleFilter={handleFilter} 
                handleSelect={handleSelect}
                handleProcessingFinishedForId={handleProcessingFinishedForId}
                handleProcessingErrorForId={handleProcessingErrorForId}
            />
        </Router>
    );
}

function AppContent({ state, handlePdbIdChange, handleReset, handleFilter, handleSelect, handleProcessingFinishedForId, handleProcessingErrorForId }) {
    const location = useLocation();

    return (
        <div className="app-container">
            <TopNav className="top-nav" />
            <Container fluid={true} className="main-container">
                <Row className="flex-grow-1">
                    <Col xs={1} className="left-nav-col">
                        {(location.pathname.startsWith(`/${constants.TAB_ASSEMBLIES}/`) ||
                            location.pathname.startsWith(`/${constants.TAB_INTERFACES}/`) ||
                            location.pathname.startsWith(`/${constants.TAB_SEQUENCE}/`) ||
                            location.pathname === '/') && <LeftNav
                            current={state.pdbId}
                            jobIds={state.jobIds}
                            clientConfigs={state.clientConfigs}
                            onFinish={handleProcessingFinishedForId}
                            onError={handleProcessingErrorForId}
                        />}
                    </Col>
                    <Col className="main-content-col">
                        <Routes>
                            <Route path="/" element={<EppicPanel
                                state={state}
                                onPdbIdChange={handlePdbIdChange}
                                onReset={handleReset}
                                onFilter={handleFilter}
                                onSelect={handleSelect}
                            />} />
                            <Route path={`/${constants.TAB_ASSEMBLIES}/:entryId`} element={<EppicPanel
                                state={state}
                                onPdbIdChange={handlePdbIdChange}
                                onReset={handleReset}
                                onFilter={handleFilter}
                                onSelect={handleSelect}
                            />} />
                            <Route path={`/${constants.TAB_INTERFACES}/:entryId`} element={<EppicPanel
                                state={state}
                                onPdbIdChange={handlePdbIdChange}
                                onReset={handleReset}
                                onFilter={handleFilter}
                                onSelect={handleSelect}
                            />} />
                            <Route path={`/${constants.TAB_INTERFACES}/:entryId/:assemblyId`} element={<EppicPanel
                                state={state}
                                onPdbIdChange={handlePdbIdChange}
                                onReset={handleReset}
                                onFilter={handleFilter}
                                onSelect={handleSelect}
                            />} />
                            <Route path={`/${constants.TAB_SEQUENCE}/:entryId`} element={<EppicPanel
                                state={state}
                                onPdbIdChange={handlePdbIdChange}
                                onReset={handleReset}
                                onFilter={handleFilter}
                                onSelect={handleSelect}
                            />} />
                            <Route path="/help" element={<HelpPage/>} />
                            <Route path='/faq' element={<FaqPage/>} />
                            <Route path='/downloads' element={<DownloadPage/>} />
                            <Route path='/release-log' element={<ReleasePage/>} />
                            <Route path='/publications' element={<PublicationPage/>} />
                            {/*handle legacy paths as redirects*/}
                            <Route path='/ewui/' element={<RedirectPage path={`/${constants.TAB_ASSEMBLIES}`}/>} />
                            {/*everything else is a 404*/}
                            <Route path='/*' element={<NotFound/>} />
                        </Routes>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

function EppicPanel({ state, onPdbIdChange, onReset, onFilter, onSelect }) {
    const { pdbId, pdbInfo, tab, interfaceClusterIdsToFilter, currAssembly, jobIds, error } = state;
    const { entryId, assemblyId } = useParams();
    const location= useLocation();
    const navigate = useNavigate();
    const eppicApiServerBaseUrl = state.clientConfigs? state.clientConfigs.eppicApiServerBaseUrl : null;

    useEffect(() => {
        if (entryId && entryId.trim() !== '') {
            const tabFromPath = location.pathname.split("/")[1]
            if (!jobIds.some(j => j.id === entryId)) {
                onPdbIdChange(entryId, tabFromPath, eppicApiServerBaseUrl);
            } else {
                // If the PDB ID is already in jobIds, update the state without fetching data
                if (assemblyId) {
                    onSelect(tabFromPath, assemblyId);
                } else {
                    onSelect(tabFromPath, null);
                }
                onPdbIdChange(entryId, tabFromPath, eppicApiServerBaseUrl);
            }
        }
        // not having onPdbIdChange is essential to avoid an infinite render loop, tip from https://dmitripavlutin.com/react-useeffect-infinite-loop/
        // not having pdbId is essential for avoiding duplicate rendering
        // entryId is essential in order for navigate and links from elsewhere to trigger useEffect
        // eppicApiServerBaseUrl is essential so that when getting PdbInfo data in handlePdbIdChange the API url already set
    }, [entryId, eppicApiServerBaseUrl]);

    const handleTabSelect = (key) => {
        if (key !== tab) {
            onSelect(key, currAssembly);
            navigate("/" + key + "/" + pdbId);
        }
    };

    return (
        <div className="App">
            <header className="App-header"></header>

            <Container fluid={true}>
                <Row>
                    <Col>
                        <Row className="sticky-header">
                            <Col xs={5}>
                                <div className="selection-dropdown-container">
                                    <SelectionDropdown
                                        clientConfigs={state.clientConfigs}
                                    />
                                </div>
                            </Col>
                        </Row>
                        { error? (
                            <ErrorPanel error={error}/>
                            )
                            : constants.isJobInLoadingState(jobIds, pdbId)?
                                (<div className="processing-alert">
                                    <Row>
                                        <Col xs={5}>
                                            <Alert className='alert-success'>
                                                <Spinner className='m-2 float-left'></Spinner>
                                                Processing uploaded file. This may take a few minutes.
                                                <br/>
                                                You can bookmark the URL and come back to it later.
                                            </Alert>
                                        </Col>
                                    </Row>
                                </div>) :
                                (
                                    <div>
                                        <Row>
                                            <Col xs={12}>
                                                <PdbInfoPanel pdbId={pdbId} pdbInfo={pdbInfo}/>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                {pdbId &&
                                                    <div>
                                            <Tabs
                                                activeKey={tab}
                                                id="main-tabs"
                                                onSelect={handleTabSelect}
                                                transition={false}
                                            >
                                                <Tab eventKey={constants.TAB_ASSEMBLIES} title="Assemblies">
                                                    <AssembliesTable
                                                        pdbId={pdbId}
                                                        onSelect={onSelect}
                                                        onFilter={onFilter}
                                                        clientConfigs={state.clientConfigs}
                                                    />
                                                </Tab>
                                                <Tab
                                                    eventKey={constants.TAB_INTERFACES}
                                                    title={Number.isInteger(currAssembly) ?
                                                        <div>
                                                            Interfaces for assembly {currAssembly}
                                                            <Image className="img-with-border" alt={'diagraminTab'}
                                                                   src={constants.getAssemblyDiagramImgUrl(state.clientConfigs.eppicApiServerBaseUrl, pdbId, currAssembly)}/>
                                                            <OverlayTrigger placement="auto"
                                                                            delay={{show: 250, hide: 400}}
                                                                            overlay={<Tooltip id="close-tooltip">Reset
                                                                                view to all interfaces</Tooltip>}>
                                                                <Link to={`/${constants.TAB_INTERFACES}/${pdbId}`}>
                                                                    <XSquareFill onClick={onReset}
                                                                                 className="reset-icon"/>
                                                                </Link>
                                                            </OverlayTrigger>
                                                        </div>
                                                        : "Interfaces"}
                                                >
                                                    <InterfTable
                                                        pdbId={pdbId}
                                                        onReset={onReset}
                                                        interfaceClusterIdsToFilter={interfaceClusterIdsToFilter}
                                                        clientConfigs={state.clientConfigs}
                                                    />
                                                </Tab>
                                                <Tab
                                                    eventKey={constants.TAB_SEQUENCE}
                                                    title={pdbInfo != null && pdbInfo.runParameters.uniProtVersion ? `Sequence information (UniProt ${pdbInfo.runParameters.uniProtVersion})` : "Sequence information"}
                                                >
                                                    <SequenceTable pdbId={pdbId}
                                                                   clientConfigs={state.clientConfigs}/>
                                                </Tab>
                                            </Tabs>
                                        </div>}
                                    </Col>
                                </Row>
                            </div> )
                        }
                    </Col>
                </Row>
            </Container>
            {!pdbId && (
                <div className="welcome-message">
                <Row>
                    <Col xs={5}>
                        <Alert className="alert-light">
                            <p>
                            EPPIC (Evolutionary Protein-Protein Interface Classifier) is a Bioinformatics server for enumeration and prediction of assemblies/interfaces of protein crystals.
                            </p>
                            <p>
                            You can type in a PDB id to get precomputed results or upload your own PDB or PDBx/mmCIF format file.
                            </p>
                        </Alert>
                    </Col>
                </Row>
                </div>)}
        </div>
    );
}

export default App;
