import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import { StarFill } from 'react-bootstrap-icons';
import '../App.css';
import * as constants from '../Data';
import { LightBox } from "./LightBox";
import { getColorFromEppicScoreObject } from "../Data";
import {useNavigate} from "react-router-dom";

// note: converted from class-based on 2024-09-05 by ChatGPT
export const AssembliesTable = (props) => {
    const [assemblies, setAssemblies] = useState([]);
    const [loaded, setLoaded] = useState(null);
    const navigate = useNavigate();

    /**
     * Fetch assemblies data from EPPIC assembly endpoint, transform assemblies information
     * and update state accordingly.
     */
    const loadAssembliesData = () => {
        if (props.pdbId != null) {
            console.log(`Fetching assemblies data for ${props.pdbId} (server ${props.clientConfigs.eppicApiServerBaseUrl})`);
            fetch(props.clientConfigs.eppicApiServerBaseUrl + constants.ASSEMBLIES_END_POINT + props.pdbId)
                .then((response) => {
                    if (!response.ok) {
                        throw new Error("Failed to fetch");
                    } else {
                        return response.json();
                    }
                })
                .then((data) => {
                    const transformedData = constants.transformAssembliesData(data);
                    setAssemblies(transformedData);
                    setLoaded(props.pdbId);
                })
                .catch(console.log);
        }
    };

    useEffect(() => {
        if (loaded !== props.pdbId) {
            loadAssembliesData();
        }
        // dep eppicApiServerBaseUrl is essential to have or otherwise it is not guaranteed that the config eppicApiServerBaseUrl is loaded at the time of running this useEffect
    }, [props.pdbId, loaded, props.clientConfigs.eppicApiServerBaseUrl]);

    const removeDuplicateInterf = (assembly) => {
        const id = [];
        const graphEdges = assembly.graphEdges;
        if (graphEdges) {
            for (const key of Object.keys(graphEdges)) {
                const interf = graphEdges[key].interfaceId;
                if (!id.includes(interf)) {
                    id.push(interf);
                }
            }
        }
        return id;
    };

    const removeDuplicaateCluster = (assembly) => {
        const cluster = [];
        const graphEdges = assembly.graphEdges;
        for (const key of Object.keys(graphEdges)) {
            const interf = graphEdges[key].interfaceClusterId;
            if (!cluster.includes(interf)) {
                cluster.push(interf);
            }
        }
        return cluster;
    };

    const handleClick = (assembly) => {
        if (Number.isInteger(assembly.id)) {
            props.onSelect(constants.TAB_INTERFACES, assembly.id);
            navigate(`/${constants.TAB_INTERFACES}/${props.pdbId}/${assembly.id}`);
        } else {
            props.onSelect(constants.TAB_INTERFACES, null);
            navigate(`/${constants.TAB_INTERFACES}/${props.pdbId}`);
        }
        props.onFilter(removeDuplicaateCluster(assembly));
    };

    if (props.pdbId == null || loaded !== props.pdbId) {
        return null;
    }

    const Assemblies = ({ assemblies }) => {
        if (assemblies != null) {
            return (
                <tbody>
                {assemblies.map((assembly) => (
                    <tr key={assembly.id}>
                        <td>
                            <LightBox
                                id={assembly.id}
                                isDiagram={false}
                                isInterface={false}
                                pdbId={props.pdbId}
                                clientConfigs={props.clientConfigs}
                                src={constants.getAssemblyImgUrl(props.clientConfigs.eppicApiServerBaseUrl, props.pdbId, assembly.id)}
                            />
                        </td>
                        <td>
                            <LightBox
                                id={assembly.id}
                                isDiagram={true}
                                isInterface={false}
                                pdbId={props.pdbId}
                                clientConfigs={props.clientConfigs}
                                src={constants.getAssemblyDiagramImgUrl(props.clientConfigs.eppicApiServerBaseUrl, props.pdbId, assembly.id)}
                            />
                        </td>
                        <td className="align-middle">{assembly.id}</td>
                        <td className="align-middle">{assembly.mmSizes}</td>
                        <td className="align-middle" dangerouslySetInnerHTML={{ __html: constants.getStoForDisplay(assembly.stoichiometries) }}></td>
                        <td className="align-middle">{assembly.symmetries}</td>
                        <td className="align-middle">
                <span
                    style={{
                        fontSize: 16,
                        textAlign: 'center',
                        fontWeight: 'bold',
                        color: getColorFromEppicScoreObject(assembly.eppic),
                    }}
                >
                  {assembly.eppic.callName ? assembly.eppic.callName.toUpperCase() : "?"}
                </span>
                            <span className="score">
                  {assembly.eppic.score !== null ? `[${assembly.eppic.score.toFixed(2)}]` : null}
                </span>
                            {assembly.eppic.score >= constants.BIO_EXCELLENT_CUTOFF && assembly.eppic.callName === 'bio' ||
                            assembly.eppic.score <= constants.XTAL_EXCELLENT_CUTOFF && assembly.eppic.callName === 'xtal' ? (
                                <StarFill className="star-excellent-conf" />
                            ) : assembly.eppic.score >= constants.BIO_GOOD_CUTOFF && assembly.eppic.callName === 'bio' ||
                            assembly.eppic.score <= constants.XTAL_GOOD_CUTOFF && assembly.eppic.callName === 'xtal' ? (
                                <StarFill className="star-good-conf" />
                            ) : (
                                <div></div>
                            )}
                        </td>
                        {removeDuplicateInterf(assembly).length === 0 ? (
                            <td className="align-middle">{removeDuplicateInterf(assembly).length + " interface(s)"}</td>
                        ) : (
                            <td className="align-middle">
                                <Button variant="outline-primary" onClick={() => handleClick(assembly)}>
                                    {removeDuplicateInterf(assembly).length + " interface(s)"}
                                </Button>
                            </td>
                        )}
                    </tr>
                ))}
                </tbody>
            );
        } else {
            return <tbody></tbody>;
        }
    };

    return (
        <Table striped bordered hover>
            <thead>
            <tr>
                <th>3D view</th>
                <th>Assembly diagram</th>
                <th>ID</th>
                <th>Macromolecular size</th>
                <th>Stoichiometry</th>
                <th>Symmetry</th>
                <th>Prediction</th>
                <th>Interfaces</th>
            </tr>
            </thead>
            <Assemblies assemblies={assemblies} />
        </Table>
    );
};
