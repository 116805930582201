import '../App.css';
import React from "react";

export const NotFound = () => {

    return (
        <div className="col-md-12 text-center mt-4">
            <h1>404</h1>
            <h2>Page not found</h2>
            <p>Sorry, the page you are looking for does not exist.</p>
        </div>
    );
};