import React from 'react';
import Footer from "./Footer";

const PublicationPage = () => {
  return (
    <div className="doc-page">
      <div>

        <h1>Publications</h1>

        <h4>Proof-of-concept using K<sub>a</sub>/K<sub>s</sub> ratios and our definition of core residue</h4>
        <p><b>CRK: an evolutionary approach for distinguishing biologically relevant interfaces from crystal contacts</b></p>
        <p>Martin A. Scharer, Markus G. Grutter and Guido Capitani</p>
        <p><i>Proteins: Structure, Function, and Bioinformatics,</i> 2010, <b>78</b>:2707-2713</p>
        <p>DOI: <a target="_blank" rel="noreferrer" href="http://onlinelibrary.wiley.com/doi/10.1002/prot.22787/abstract">10.1002/prot.22787</a></p>

        <h4>Main paper describing the EPPIC method and the DCxtal and DCbio interface datasets</h4>
        <p><b>Protein interface classification by evolutionary analysis</b></p>
        <p>Jose M Duarte, Adam Srebniak, Martin A Scharer and Guido Capitani</p>
        <p><i>BMC Bioinformatics,</i> 2012, <b>13</b>:334</p>
        <p>DOI: <a target="_blank" rel="noreferrer" href="http://www.biomedcentral.com/1471-2105/13/334">10.1186/1471-2105-13-334</a></p>

        <h4>Performance of EPPIC on membrane protein interfaces and TMPBio interface dataset</h4>
        <p><b>An analysis of oligomerization interfaces in transmembrane proteins</b></p>
        <p>Jose M Duarte, Nikhil Biyani, Kumaran Baskaran and Guido Capitani</p>
        <p><i>BMC Structural Biology,</i> 2013, <b>13</b>:21</p>
        <p>DOI: <a target="_blank" rel="noreferrer" href="http://www.biomedcentral.com/1472-6807/13/21">10.1186/1472-6807-13-21</a></p>

        <h4>PDB-wide assessment</h4>
        <p><b>A PDB-wide, evolution-based assessment of protein–protein interfaces</b></p>
        <p>Kumaran Baskaran, Jose M Duarte, Nikhil Biyani, Spencer Bliven and Guido Capitani</p>
        <p><i>BMC Structural Biology,</i> 2014, <b>14</b>:22</p>
        <p>DOI: <a target="_blank" rel="noreferrer" href="http://www.biomedcentral.com/1472-6807/14/22">10.1186/s12900-014-0022-0</a></p>

        <h4>Assembly prediction, version 3</h4>
        <p><b>Automated evaluation of quaternary structures from protein crystals</b></p>
        <p>Spencer Bliven, Aleix Lafita, Althea Parker, Guido Capitani, Jose M. Duarte</p>
        <p><i>PLoS Computational Biology,</i> 2018, 14(4): e1006104</p>
        <p>DOI: <a target="_blank" rel="noreferrer" href="https://doi.org/10.1371/journal.pcbi.1006104">10.1371/journal.pcbi.1006104</a></p>
        <p><i>Please cite this paper if you use any service from this web server</i></p>
      </div>
      <Footer fixbottom={false}/>
    </div>
  );
};

export default PublicationPage;