import React, { useState, useRef } from 'react';
import { Form, InputGroup, Dropdown, Button } from 'react-bootstrap';
import {FiUpload} from "react-icons/fi";
import {useNavigate} from "react-router-dom";
import { TAB_ASSEMBLIES, FILE_UPLOAD_END_POINT } from '../Data';

const SelectionDropdown = ({ clientConfigs }) => {
    const [inputType, setInputType] = useState('entryId'); // Default input type
    const [idValue, setIdValue] = useState('');
    const [file, setFile] = useState(null);
    const navigate = useNavigate();

    // Reference to the hidden file input
    const fileInputRef = useRef(null);

    const handleSelect = (eventKey) => {
        setInputType(eventKey);
        // Reset input values when switching types
        setIdValue('');
        setFile(null);
        // Reset the file input's value to allow re-uploading the same file if needed
        if (fileInputRef.current) {
            fileInputRef.current.value = null;
        }
    };

    const handleFileClick = () => {
        // Trigger the hidden file input when the custom button is clicked
        fileInputRef.current.click();
    };

    const handleFileChange = (e) => {
        if (e.target.files.length > 0) {
            setFile(e.target.files[0]);
            // Reset the file input's value to allow selecting the same file again
            e.target.value = null;
        }
    };

    const handleSubmitId = () => {
        navigate(`/${TAB_ASSEMBLIES}/${idValue}`)
    };

    const handleSubmitFile = () => {
        const reader = new FileReader();
        reader.onload = () => {
            const fileName = file.name.toLowerCase();

            // drop base64 metadata and pad message
            let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
            if ((encoded.length % 4) > 0) {
                encoded += '='.repeat(4 - (encoded.length % 4));
            }
            const data = {
                data: encoded,
                fileName: fileName,
                skipEvolAnalysis: clientConfigs.skipEvolAnalysis,
                email: null
            };

            fetch(clientConfigs.eppicApiServerBaseUrl + FILE_UPLOAD_END_POINT, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(data)
            })
                .then(response => {
                    if (!response.ok) {
                        response.text().then(errorMessage => {
                            alert(errorMessage);
                        })
                    } else {
                        return response.json();
                    }
                })
                .then(items => {
                    if (items != null) {
                        navigate(`/${TAB_ASSEMBLIES}/${items.submissionId}`);
                    }
                })
                .catch(error => {
                    fileInputRef.current.value = null;
                    alert('Could not submit file to server: ' + error.message);
                });

        }
        reader.readAsDataURL(file);

    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (inputType === 'entryId') {
            // Handle entryId submission
            if (idValue.trim() === '') {
                // TODO use Form.Control.Feedback
                alert('Please enter a PDB id.');
                return;
            }
            handleSubmitId();
            // clear the input box
            setIdValue('');
        } else if (inputType === 'file') {
            // Handle File submission
            if (!file || file.name === "") {
                // TODO use Form.Control.Feedback
                alert('Please upload a file.');
                return;
            }
            handleSubmitFile();
            setFile(null);
        }
    };

  return (
      <div>
          <Form onSubmit={handleSubmit}>
          <InputGroup>
            <Dropdown onSelect={handleSelect} as={InputGroup.Prepend} variant="outline-secondary">
              <Dropdown.Toggle split variant="outline-secondary" id="dropdown-split-basic" style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }} />
              <Dropdown.Menu>
                <Dropdown.Item eventKey="entryId">PDB ID</Dropdown.Item>
                <Dropdown.Item eventKey="file">File upload</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            {inputType === 'file' ? (
              <div style={{ display: 'flex', flexGrow: 1 }}>
                <Button
                  variant="outline-secondary"
                  onClick={handleFileClick}
                  style={{ flexGrow: 1, borderRadius: 0 }}
                >
                  <FiUpload /> {file ? `Selected file: ${file.name}` : 'File Upload'}
                </Button>
                <Form.Control
                  type="file"
                  ref={fileInputRef}
                  onChange={handleFileChange}
                  style={{ display: 'none' }}
                />
              </div>
            ) : (
              <Form.Control
                  type="text"
                  aria-label="Text input with dropdown button"
                  placeholder="Enter PDB ID"
                  value={idValue}
                  onChange={(e) => setIdValue(e.target.value)}
                  className="bg-light"
                  style={{ flexGrow: 1, borderRadius: 0 }}
              />
            )}
            <Button
                variant="primary"
                className="ml-0"
                type="submit"
                style={{ borderRadius: '0 5px 5px 0', borderLeft: 'none' }}
            >
              Submit
            </Button>
          </InputGroup>

          </Form>
      </div>
  );
}

export default SelectionDropdown;
