import React, { useEffect } from 'react';
import '../App.css';

export const PdbInfoPanel = ({ pdbId, pdbInfo }) => {

    // Effect to handle fetching or setting pdbInfo based on pdbId (if needed)
    useEffect(() => {
        if (pdbId === null) return;
    }, [pdbId]);

    if (pdbId == null || pdbInfo == null) {
        return null;
    }

    return (
        <div style={{ paddingTop: 20 }}>
            <h4 className="text-left">
                {pdbInfo.title}
            </h4>
            <p className="text-left">
                {pdbInfo.expMethod} - {pdbInfo.spaceGroup} - {pdbInfo.resolution.toFixed(2)} Å
            </p>
        </div>
    );
};
