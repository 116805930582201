import React from 'react';
import '../App.css';

const Footer = ({fixbottom}) => {

    return (
        <div>
            <footer className={`row row-cols-1 row-cols-sm-2 row-cols-md-5 py-5 my-5 border-top ${fixbottom? "fixed-bottom" : ""}`}>

                <div className="col mb-3">
                    <h5>About</h5>
                    <ul className="nav flex-column">
                        
                        <li className="nav-item mb-2"><a
                            href="https://journals.plos.org/ploscompbiol/article?id=10.1371/journal.pcbi.1006104"
                            className="nav-link p-0 text-body-secondary">Citing us</a>
                        </li>
                        <li className="nav-item mb-2"><a href="/faq"
                                                         className="nav-link p-0 text-body-secondary">FAQs</a></li>
                        <li className="nav-item mb-2"><a href="https://www.rcsb.org/pages/contactus"
                                                         className="nav-link p-0 text-body-secondary">Contact us</a></li>
                    </ul>
                </div>

                <div className="col mb-3">
                </div>

                <div className="col mb-3">
                    <h5>Supported by</h5>
                    <ul className="nav flex-column">
                        <li className="nav-item mb-2"><a href="https://www.rcsb.org" target="_blank"
                                                         rel="noreferrer">
                            <img
                                className="img-footer"
                                src="/public/images/rcsb-logo_small.png" title="RCSB Protein Data Bank"></img>
                        </a>
                        </li>
                    </ul>
                </div>

                <div className="col mb-3">
                    <h5>Past funding from</h5>
                    <ul className="nav flex-column">

                        <li className="nav-item mb-2"><a href="https://www.snf.ch" target="_blank" rel="noreferrer">
                            <img
                                className="img-footer"
                                src="/public/images/SNSF_Logo_small.jpg"
                                title="Swiss National Science Foundation"></img>
                        </a>
                        </li>

                        <li className="nav-item mb-2"><a href="https://www.psi.ch" target="_blank" rel="noreferrer">
                            <img
                                className="img-footer"
                                src="/public/images/psi-logo_small.png" title="Paul Scherrer Institute"></img>
                        </a>
                        </li>
                    </ul>

                </div>
            </footer>
        </div>
    );
};
export default Footer;