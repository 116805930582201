import React, {Component} from 'react';
import Table from 'react-bootstrap/Table';
import {StarFill, Arrows} from 'react-bootstrap-icons';
import '../App.css';
import * as constants from '../Data';
import {LightBox} from "./LightBox";
import {getColorFromEppicScoreObject} from "../Data";
import {Tooltip} from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

export class InterfTable extends Component {

    constructor(props) {
        super(props);
        this.state = {interfs: [], loaded: null};
    }

    /**
     * fetch Interface data from EPPIC endpoint, update state respectively
     */
    loadInterfData() {
        if (this.props.pdbId != null) {
            console.log("Fetching interface data for " + this.props.pdbId);
            fetch(this.props.clientConfigs.eppicApiServerBaseUrl + constants.INTERF_END_POINT + this.props.pdbId)
                .then((response) => {
                    if (!response.ok) {
                        throw new Error("Fail to fetch");
                    } else {
                        return response.json();
                    }
                })
                .then((data) => {
                    const transfInterfs = constants.transformInterfData(data);
                    this.setState({interfs: transfInterfs, loaded: this.props.pdbId})
                })
                .catch(console.log)
        }
    }

    /**
     * Filter the interface data by cluster ID
     *
     * @param id selected cluster ID
     * @returns {T[]} interfaces with specified cluster ID
     */
    clusterFilter(id) {
        return this.state.interfs.filter(interf => {
            return interf.clusterId === id;
        })
    }

    /**
     * Return average area of the selected interface cluster
     * @param group selected interface cluster
     * @returns {string} the average area of the cluster
     */
    getAverage(group) {
        let sum = 0;
        for (let i = 0; i < group.length; i++) {
            sum += group[i].area;
        }
        let avg = sum / group.length;
        return avg.toFixed(2);
    }

    getIsologousConsensus(group) {
        let isoCount = 0;
        for (let i = 0; i < group.length; i++) {
            isoCount += group[i].isologous? 1 : 0;
        }
        return (isoCount > group.length/2);
    }

    /**
     * Render interfaces grouped by cluster ID
     *
     * @returns {*[]} list of interface clusters
     */
    renderItem() {
        // list of interface clusters that are to be rendered
        const summary = [];
        let i = 1;
        while (this.clusterFilter(i).length > 0) {
            if (this.props.interfaceClusterIdsToFilter == null || this.props.interfaceClusterIdsToFilter.includes(i)) {
                // initialize one cluster to be rendered
                summary.push({
                    cluster: "Cluster: " + i,
                    isologous: this.getIsologousConsensus(this.clusterFilter(i)),
                    area: this.getAverage(this.clusterFilter(i)),
                    interface: this.clusterFilter(i),
                    number: this.clusterFilter(i).length + " interface(s)"
                });
            }
            i++;
        }

        const clusters = []

        for (let index in summary) {
            let item = summary[index];

            // fill overall information for one cluster
            const itemRows = [
                <tr
                    key={"row-data-" + item.cluster}
                    style={{fontWeight: 'bold', borderWidth: 50}}>
                    <td>{item.number}</td>
                    <td>{item.cluster}</td>
                    <td></td>
                    <td>{item.area}</td>
                    <td>
                        <OverlayTrigger placement="right" overlay={<Tooltip id="iso-tooltip">Interface with isologous character: binding sites on either side are identical or very similar, i.e. the interface has 2-fold (approximate) symmetry</Tooltip>}>
                            {item.isologous? <Arrows width={25} height={25} className="border border-secondary rounded isoarrow"/> : <div/>}
                        </OverlayTrigger>
                    </td>
                    <td></td>
                    <td></td>
                </tr>
            ];

            // fill interface-specific information for one cluster
            itemRows.push(
                item.interface.map((interf) => (
                    <tr key={"row-expanded-" + interf.interfaceId} style={{background: 'whitesmoke'}}>
                        <td><LightBox id={interf.interfaceId}
                                      isDiagram={false}
                                      isInterface={true}
                                      chainIds = {constants.getChainIdsFromInterfObj(interf)}
                                      pdbId={this.props.pdbId}
                                      clientConfigs={this.props.clientConfigs}
                                      src={constants.getInterfImgUrl(this.props.clientConfigs.eppicApiServerBaseUrl, this.props.pdbId, interf.interfaceId)}
                                      >
                        </LightBox></td>
                        <td className="align-middle">{interf.interfaceId}</td>
                        <td className="align-middle">{interf.chain1 + " + " + interf.chain2}</td>
                        <td className="align-middle">{interf.area.toFixed(2)}</td>
                        <td className="align-middle">
                            <OverlayTrigger placement="right" overlay={<Tooltip id="op-tooltip">{interf.operator}</Tooltip>}>
                                <img src={constants.getOpTypeImgUrl(interf.operatorType, interf.infinite)} alt="Operator"/>
                            </OverlayTrigger>
                        </td>
                        <td className="align-middle">
                            <span style={{color: getColorFromEppicScoreObject(interf["eppic-cs"])}}>
                            {interf["eppic-cs"].callName}
                            </span>
                            <span className="score">
                            {interf["eppic-cs"].score !== undefined ? `[${interf["eppic-cs"].score.toFixed(2)}]` : interf['eppic-cs'].callReason}
                            </span>
                        </td>
                        <td className="align-middle">
                            <span style={{
                                fontSize: 16,
                                textAlign: 'center',
                                fontWeight: 'bold',
                                color: getColorFromEppicScoreObject(interf.eppic)
                            }}>
                            {interf["eppic"].callName.toUpperCase()}
                            </span>
                            <span className="score">
                                {interf["eppic"].score !== null ? `[${interf["eppic"].score.toFixed(2)}]` : null}
                            </span>
                            {
                                (interf["eppic"].score >= constants.BIO_EXCELLENT_CUTOFF && interf["eppic"].callName === 'bio') || (interf["eppic"].score <= constants.XTAL_EXCELLENT_CUTOFF && interf["eppic"].callName === 'xtal') ?
                                    <StarFill className="star-excellent-conf"/>
                                    :
                                    (interf["eppic"].score >= constants.BIO_GOOD_CUTOFF && interf["eppic"].callName === 'bio') || (interf["eppic"].score <= constants.XTAL_GOOD_CUTOFF && interf["eppic"].callName === 'xtal') ?
                                        <StarFill className="star-good-conf"/>
                                        :
                                        <div></div>
                            }
                        </td>
                    </tr>
                ))
            );

            clusters.push(itemRows);
        }
        return clusters;
    }

    render() {
        if (this.props.pdbId == null) {
            return null;
        }

        if (this.state.loaded !== this.props.pdbId) {
            this.loadInterfData();
            return null;
        }

        let allItemRows = this.renderItem();

        return (
            <div>
                <Table bordered hover>
                    <thead>
                    <tr>
                        <th>3D view</th>
                        <th>ID</th>
                        <th>Chains</th>
                        <th>Area (Å<sup>2</sup>)</th>
                        <th>Operator</th>
                        <th>Core-Surface</th>
                        <th>Final</th>
                    </tr>
                    </thead>

                    <tbody>
                    {allItemRows}
                    </tbody>
                </Table>

            </div>
        );
    }
}