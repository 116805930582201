import {useEffect} from 'react';
import '../App.css';
import {useLocation, useNavigate} from "react-router-dom";
import * as constants from "../Data";

export const RedirectPage = () => {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        // this handles the previous eppic paths with style /ewui/#id/1smt or /ewui/#interfaces/1smt, and redirects to the new ones
        const tokens = location.hash.substring(1).split('/');
        let path = tokens[0];
        if (path === 'id') {
            path = constants.TAB_ASSEMBLIES;
        } else if (path === 'interfaces') {
            path = constants.TAB_INTERFACES;
        } else {
            // go to home page (e.g. for /ewui legacy url to be caught and sent to home page)
            navigate('/');
            return;
        }
        const entryId = tokens[1];
        navigate('/' + path + `/${entryId}`)
    }, [location]);

};
