import React, { useState, useEffect, useRef } from 'react';
import '../App.css';
import * as constants from '../Data';
import {Network} from 'vis';
import {Viewer} from '@rcsb/rcsb-molstar/build/dist/viewer/rcsb-molstar';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import {Tooltip} from "react-bootstrap";

export const Molstar = ({ isInterface, chainIds, pdbId, id, clientConfigs }) =>  {
    const [molstar, setMolstar] = useState(null);
    const [config, setConfig] = useState(null);
    const [url, setUrl] = useState(null);
    const [coreResidue, setCoreResidue] = useState(null);
    const [componentsAdded, setComponentsAdded] = useState(false);

    useEffect(() => {
        function getQueryParam(id) {
            const a = new RegExp(id + '=([^&#=]*)', 'i')
            const m = a.exec(window.location.search)
            return m ? decodeURIComponent(m[1]) : undefined
        }

        const _config = getQueryParam('config');
        const config = _config && JSON.parse(_config);

        const viewer = new Viewer('viewer', {
            showImportControls: true,
            showSessionControls: false,
            layoutShowLog: false,
            layoutShowControls: false,
            showMembraneOrientationPreset: true,
            showNakbColorTheme: true,
            detachedFromSierra: true,
        });
        setMolstar(viewer);
        setConfig(config);

        if (isInterface) {
            const coreUrl = clientConfigs.eppicApiServerBaseUrl + constants.INTERF_RESIDUE_END_POINT + pdbId + "/" + id;
            fetch(coreUrl)
                .then((response) => {
                    if (!response.ok) {
                        throw new Error("Fail to fetch");
                    }
                    return response.json();
                })
                .then((data) => {
                    setCoreResidue(data.filter(each => each.region === 3));
                })
                .catch(console.log);
        }
    }, [isInterface, pdbId, id]);

    useEffect(() => {
        if (isInterface) {
            if (url == null) {
                setUrl(constants.getInterfCifUrl(clientConfigs.eppicApiServerBaseUrl, pdbId, id));
            }

            if (molstar && !componentsAdded && coreResidue) {
                setUrl(constants.getInterfCifUrl(clientConfigs.eppicApiServerBaseUrl, pdbId, id));
                molstar.loadStructureFromUrl(url, 'mmcif', false, config).then((resolved) => {
                    molstar.createComponent(
                        'Chain 1 Core Residues',
                        coreResidue.filter(cr => cr.side).map(cr => ({
                            modelId: resolved.structure.obj.data.units[0].model.id,
                            labelAsymId: chainIds[0],
                            labelSeqId: cr['residueNumber']
                        })),
                        "ball-and-stick"
                    );
                    molstar.createComponent(
                        'Chain 2 Core Residues',
                        coreResidue.filter(cr => !cr.side).map(cr => ({
                            modelId: resolved.structure.obj.data.units[0].model.id,
                            labelAsymId: chainIds[1],
                            labelSeqId: cr['residueNumber']
                        })),
                        "ball-and-stick"
                    );
                });
                setComponentsAdded(true);
            }
        } else {
            if (url == null) {
                setUrl(constants.getAssemblyCifUrl(clientConfigs.eppicApiServerBaseUrl, pdbId, id));
            }
            if (molstar) {
                molstar.loadStructureFromUrl(url, 'mmcif', false, config);
            }
        }
    }, [isInterface, molstar, config, url, chainIds, coreResidue, componentsAdded, pdbId, id]);

    return (
        <div>
            <div id={'viewer'}/>
        </div>
    );
}

export const LightBoxViewer = ({ children, src, isDiagram, pdbId, id, isInterface, chainIds, clientConfigs }) => {
    const [isOpen, setIsOpen] = useState(true);
    const [nodes, setNodes] = useState([]);
    const [edges, setEdges] = useState([]);
    const container = useRef(null);

    const toggleIsOpen = () => setIsOpen(!isOpen);
    const handleChild = (e) => e.stopPropagation();

    const diagramStyle = {
        background: "white",
        display: 'inline-block',
        width: "70%",
        height: "90%",
        position: "relative",
        border: "solid grey 1px"
    };

    useEffect(() => {
        if (src) {
            setNodes(src.nodes || []);
            setEdges(src.edges || []);
        }
    }, [src]);

    useEffect(() => {
        if (isOpen && isDiagram && container.current && nodes.length > 0 && edges.length > 0) {
            const initialNodeSize = 10;
            const options = {
                nodes: {
                    shape: 'dot',
                    size: initialNodeSize,
                },
                edges: {
                    smooth: {
                        enabled: false,
                        type: "dynamic",
                        roundness: 1.0
                    },
                    arrows: 'to',
                },
                physics: false
            };

            if (container.current != null) {
                const network = new Network(container.current, {nodes, edges}, options);
                network.on("zoom", function (params) {
                    options.nodes.size = initialNodeSize / params.scale;
                    network.setOptions(options);
                });
            }
        }
    }, [isOpen, isDiagram, nodes, edges]);

    return (
        <div onClick={toggleIsOpen}>
            {children}
            {isOpen && (
                <div onClick={toggleIsOpen} style={{
                    position: 'fixed',
                    top: '0',
                    left: '0',
                    height: '100vh',
                    width: '100vw',
                    backgroundColor: 'rgba(0,0,0,0.7)',
                    cursor: 'pointer',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex:1000,
                }}>
                    {isDiagram ? (
                        <div onClick={handleChild} ref={container} style={diagramStyle}/>
                    ) : (
                        <div onClick={handleChild} style={diagramStyle}>
                            <Molstar pdbId={pdbId} isInterface={isInterface} chainIds={chainIds} id={id} clientConfigs={clientConfigs}/>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

export const LightBox = ({ isDiagram, pdbId, id, isInterface, chainIds, src, clientConfigs }) => {
    const [diagram, setDiagram] = useState(null);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (isDiagram && pdbId && id) {
            fetch(clientConfigs.eppicApiServerBaseUrl + constants.ASSEMBLIES_DIAGRAM_END_POINT + pdbId + "/" + id)
                .then((response) => {
                    if (!response.ok) {
                        throw new Error("Fail to fetch");
                    }
                    return response.json();
                })
                .then((data) => {
                    setDiagram(data);
                })
                .catch(console.log);
        }
    }, [isDiagram, pdbId, id]);

    const onClickOpen = () => setOpen(!open);

    // TODO make text specific to 3D or diagram
    let tooltipText = "Click to view";

    if (!open) {
        return (
            <OverlayTrigger placement="auto" overlay={<Tooltip id="view-tooltip">{tooltipText}</Tooltip>}>
                <img src={src} alt="Assembly" onClick={onClickOpen} className="img-with-viz"/>
            </OverlayTrigger>
        )
    }

    const image =
        <OverlayTrigger placement="auto" overlay={<Tooltip id="view-tooltip">{tooltipText}</Tooltip>}>
            <img src={src} alt="Assembly" className="img-with-viz"/>
        </OverlayTrigger>


    return (
        <div>
            <LightBoxViewer
                id={id}
                pdbId={pdbId}
                isInterface={isInterface}
                chainIds = {chainIds}
                children={image}
                src={diagram}
                isDiagram={isDiagram}
                clientConfigs={clientConfigs}
            />
        </div>
    );
}