import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Image from 'react-bootstrap/Image';
import * as constants from '../Data';

const TopNav = () => {
  const [current, setCurrent] = useState('home');
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const currentTab = location.pathname.split('/')[1] || 'home';
    setCurrent(currentTab);
  }, [location]);

  const handleSelect = (selectedKey) => {
    setCurrent(selectedKey);
    navigate(`/${selectedKey}`);
  };

  const linkStyle = (isActive) => ({
    color: isActive ? 'black' : 'gray',
    fontWeight: isActive ? '600' : '400',
    fontSize: '14px',
    textDecoration: 'none',
    marginLeft: '30px',
  });

  return (
    <nav style={{ display: 'flex', alignItems: 'center', borderBottom: '0.5px solid #d4d4d4', paddingTop: '12px', paddingBottom: '12px', backgroundColor: '#f5f5f5' }}>
      <div style={{ flex: 1, display: 'flex' }}>
        <Link
          to="/"
          style={linkStyle(current === 'home'||current === constants.TAB_ASSEMBLIES)}
          onClick={() => handleSelect('home')}
        >
          Home
        </Link>
        <Link
          to="/downloads"
          style={linkStyle(current === 'downloads')}
          onClick={() => handleSelect('downloads')}
        >
          Downloads
        </Link>
        <Link
          to="/help"
          style={linkStyle(current === 'help')}
          onClick={() => handleSelect('help')}
        >
          Help
        </Link>
        <Link
          to="/faq"
          style={linkStyle(current === 'faq')}
          onClick={() => handleSelect('faq')}
        >
          F.A.Q.
        </Link>
        <Link
          to="/release-log"
          style={linkStyle(current === 'release-log')}
          onClick={() => handleSelect('release-log')}
        >
          Release log
        </Link>
        <Link
          to="/publications"
          style={linkStyle(current === 'publications')}
          onClick={() => handleSelect('publications')}
        >
          Publications
        </Link>
      </div>
      <Image 
        style={{marginRight:'30px'}}
        src="/public/images/eppic-logo.png" rounded height="25" width="62" />
    </nav>
  );
};

export default TopNav;

