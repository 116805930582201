import React, {useEffect, useState} from 'react';
import Nav from 'react-bootstrap/Nav';
import {useNavigate} from "react-router-dom";
import * as constants from '../Data';
import {Spinner} from "react-bootstrap";
import {checkStatus, JobStatus} from "../Data";

export function LeftNav({ current, jobIds, clientConfigs, onFinish, onError }) {
    const navigate = useNavigate();

    const handleSelect = (e) => {
        navigate(`/${constants.TAB_ASSEMBLIES}/${e}`);
    };

    return (
        <div style={{
            height: '100vh', 
            borderRight: '0.5px solid #d4d4d4', 
            backgroundColor: '#fafafa'
        }}>
            <Nav
                activeKey={current}
                className="flex-column"
                onSelect={handleSelect}
            >
                {jobIds.map( jobIdObj => (
                    <NavLinkIdBox
                        key={jobIdObj.id}
                        id={jobIdObj.id}
                        processingId={constants.isJobStatusProcessing(jobIdObj.jobStatus)? jobIdObj.id : null}
                        jobStatus={jobIdObj.jobStatus}
                        isCurrent={jobIdObj.id === current}
                        clientConfigs={clientConfigs}
                        onSelect={handleSelect}
                        onFinish={onFinish}
                        onError={onError}>
                    </NavLinkIdBox>
                ))}
            </Nav>
        </div>
    );
}

export function NavLinkIdBox({id, processingId, jobStatus, isCurrent, clientConfigs, onSelect, onFinish, onError}) {
    const [status, setStatus] = useState(null);

    useEffect(() => {
        let isMounted = true;
        if (processingId === null) {
            return;
        }
        const interval = setInterval(() => {
            console.log(`Started tracking of processing for job ${processingId}`);
            checkStatus(clientConfigs.eppicApiServerBaseUrl, processingId)
                .then((apiJobStatus) => {
                    if (isMounted) {
                        setStatus(apiJobStatus);
                        if (apiJobStatus === JobStatus.FINISHED) {
                            console.log(`Stopped tracking of processing for job ${processingId} because it is FINISHED`)
                            clearInterval(interval);
                            onFinish(processingId);
                        } else if (apiJobStatus === JobStatus.ERROR) {
                            console.log(`Stopped tracking of processing for job ${processingId} because it is ERROR`)
                            clearInterval(interval);
                            onError(processingId);
                        }
                    }
                })
                .catch((error) => {
                    console.error('Error fetching status:', error);
                });
        }, 2000); // Adjust the interval time as needed

        return () => {
            isMounted = false;
            clearInterval(interval);
        };
    }, [processingId, onFinish, onError]);

    return (
        <Nav.Link
            key={id}
            onClick={() => onSelect(id)}
            style={{
                paddingVertical: '10px',
                color: isCurrent ? 'black' : 'gray',
                fontSize: '14px',
                fontWeight: isCurrent ? '600' : '400',
                cursor: 'pointer',
                textAlign: 'center'
            }}
        >
            {id.length > 4 ? id.substring(0, 4) + ".." + id.substring(id.length - 4) : id}
            {constants.isJobStatusProcessing(jobStatus)?
                <Spinner style={{height: '14px', width: '14px', marginLeft: '2px', fontWeight: 'initial'}}/>
                :
                <div></div>}
        </Nav.Link>
    )
}
