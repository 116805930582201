import React from 'react';
import {Arrows} from 'react-bootstrap-icons';
import {StarFill} from 'react-bootstrap-icons';
import Footer from "./Footer";
import {Figure} from "react-bootstrap";
import FigureCaption from "react-bootstrap/FigureCaption";

const HelpPage = () => {
  return (
    <div className="doc-page">
      <div>
        <div className="eppic-help-subtopic" id="about">
          <h1>Help</h1>


          <p>
            EPPIC (Evolutionary Protein-Protein Interface Classifier) aims at predicting the quaternary structure of
            proteins from crystal structures. It first classifies the interfaces present in a crystal structure to
            determine whether they are biologically relevant or not. Then it enumerates all topologically valid
            assemblies in the crystal resulting from different combinations of the interfaces. Finally, it provides a
            prediction of the most likely quaternary assembly based on the individual interfaces' scores.
          </p>

          <p>In addition to that, it provides information of general use for a number of structural biology
            applications:</p>
          <ul>
            <li>
              Precomputed Multiple Sequence Alignments (MSAs) of closely related homologs (within 60% sequence identity)
              for every protein in the PDB or for user-uploaded structures
            </li>
            {/*<li>*/}
            {/*  Colored-by-sequence-entropy surface representation using the browser embedded <a*/}
            {/*    href="https://github.com/arose/ngl">NGL</a> viewer.*/}
            {/*  Very useful for looking at features present at protein surfaces: interfaces, binding sites, etc. (<a*/}
            {/*    href="#homologs-window">more</a>)*/}
            {/*</li>*/}
            <li>Number and distribution of core residues in interfaces</li>
            <li>
              Symmetry features of interfaces: the crystallographic operators generating each interface are provided and
              are depicted in red if they are conducive to infinite assemblies. Also, the <a
                href="http://en.wikipedia.org/wiki/Heterologous" target="_blank"
                rel="noreferrer">isologous</a> character
              of each interface cluster is indicated with icon <Arrows
                className="border border-secondary rounded isoarrow"></Arrows>.
              Absence of the icon indicates that the interface cluster is <a
                href="http://en.wikipedia.org/wiki/Heterologous" target="_blank" rel="noreferrer">heterologous</a>.
            </li>
          </ul>
        </div>

        {/* Rest of the content */}
        <h2 id="input">Inputting data</h2>
        <div>
          <Figure>
            <img
                className="eppic-help-img"
                src="/public/images/inputbox.png"
                alt="Screenshot of the input box"

            />
            <FigureCaption>The input box and dropdown</FigureCaption>
          </Figure>
          <p>
            The server has precomputed results for all crystallographic structures in the PDB. If you are interested
            in a specific PDB id, you can simply
            enter it and get results immediately. You can also submit your own protein structure by uploading a
            PDB/mmCIF file (use the drop-down to select "File upload"). If you upload your own file, the calculation
            will be triggered and the results are
            produced within a few minutes. Structures with many protein entities (unique sequences) will take longer.
          </p>
          <p>
            Every new job run on the server is assigned a unique job identifier, a long alphanumerical string that is
            only known by the user who submits it and that is very hard to guess. This guarantees the privacy of your
            data (<a href="#job-identifiers">more</a>).
          </p>
        </div>

        <h2 id="assemblies-table">The assemblies table</h2>
        <p>
          All the topologically valid assemblies detected in the crystal are listed. Valid assemblies are those that
          exhibit Point Group Symmetry and that are isomorphous across the unit cell, i.e. all molecules are
          interacting in the same way throughout the lattice.
        </p>
        <div className="eppic-help-subtopic">
          <div className="eppic-help-subtopic-left-small">
            <Figure>
              <img
                  className="eppic-help-img"
                  src="/public/images/assemblies-table.png"
                  alt="Screenshot of the assemblies table"
              />
              <FigureCaption>The assemblies table</FigureCaption>
            </Figure>
          </div>
          <div className="eppic-help-subtopic-right-big">
            <p>
              Each assembly is represented by a thumbnail image in fat ribbon representation with different subunits in
              different colors (1st column). Also a 2-dimensional graph diagram of the assembly is shown, with nodes
              being the chains and edges the interfaces between them (2nd column). Distinct molecular entities and
              distinct interfaces are depicted with different colors. Next appear the macromolecular size,
              stoichiometry (the successive letters represent different molecular entities, not chain ids) and point
              group symmetry (Cn for cyclic symmetries, Dn for dihedral symmetries, T for tetrahedral, O for
              octahedral and I for icosahedral).
            </p>
            <p>
              Note that disjoint assemblies will be shown separated by commas in the size, symmetry, and stoichiometry
              columns. Disjoint assemblies are those where not all components of the crystal form a single assembly but
              instead several disjoint ones, e.g. a crystal containing 2 protein entities will always contain a
              disjoint assembly formed by 2 independent monomers (one for each entity).
            </p>
            <p>
              The prediction column provides the predicted assembly (marked as <font color="green">BIO</font>), while
              all others are marked as <font color="red">XTAL</font>. The predicted assembly corresponds to that with
              the highest calculated probability, based on the scores of the interfaces that form it. The probability
              values for each assembly appear next to the call. An estimation of the prediction confidence is
              provided with star icons: golden star <StarFill className="star-excellent-conf"/> for
              high confidence, gray star <StarFill className="star-good-conf"/> for medium confidence,
              no star for low confidence.
            </p>
            <p>
              {/*For PDB deposited entries, the assembly corresponding to the first PDB biounit assignment is marked with*/}
              {/*a small <img src="public/images/pdb_biounit_annotation.png" alt="PDB biounit icon"/> icon. */}
              The last
              column shows how many interfaces compose the assembly. Clicking on the button, the interfaces table is
              shown with only those interfaces belonging to the assembly. The view can be reset to show all interfaces
              by clicking on the close icon in the tab title.
            </p>
          </div>
        </div>

        <h2 id="interfaces-table">The interfaces table</h2>
        <p>
          This table provides a view of all the pairwise protein-protein interfaces present in the crystal.
          Clusters of similar interfaces are shown in groups of rows. Interface clusters are
          interfaces that share a certain amount of contact similarity. For each cluster,
          a header row displays the cluster id, number of member interfaces and the members' average area.
        </p>
        <p>
          An assessment of the biological relevance of each
          pairwise interface is provided, based on a geometrical and evolutionary criteria. The final assessment
          that is a composite of the other 2 is provided in the right-most column.
        </p>
        <ul>
          <li>
            <b>geometry</b>: number of core residues (at <b>95% burial</b>), indicating how good the packing in the
            interface is. Note this score is not shown in the user interface but it is taken into account for the final
            call.
          </li>
          <li>
            <b>core-surface score</b>: a z-score of sequence entropy of core residues (at <b>70% burial</b>) versus
            random samples of all surface residues
          </li>
        </ul>

        <div className="eppic-help-subtopic">
          <Figure>
            <img
                className="eppic-help-img"
                src="/public/images/interfaces-table.png"
                alt="Screenshot of the main interface table"
            />
            <FigureCaption>The interfaces table</FigureCaption>
          </Figure>
          <div className="eppic-help-subtopic-right-big">
            <p>
              Each of these indicators have predefined score thresholds to produce one of the calls:
            </p>
            <ul>
              <li>
                <font color="green">BIO</font>, the interface is biologically relevant
              </li>
              <li>
                <font color="red">XTAL</font>, the interface is only a crystal lattice contact
              </li>
              <li>
                NOPRED, there is not enough information available to make a decision (usually not enough sequence data)
              </li>
            </ul>
            <p>
              You can see the scores for each of the indicators next to the
              bio/xtal/nopred labels.
            </p>
            {/*<p>*/}
            {/*  Warnings may appear on the right side of the interfaces table marked by the*/}
            {/*  icon{' '}*/}
            {/*  <img src="public/images/warning_icon.png" alt="Warning icon"/>. By hovering the mouse over the icon,*/}
            {/*  one can see different issues for which the prediction should be taken with care, e.g. engineered residues*/}
            {/*  at the interface core or rim, metal ions mediating the interface, atom clashes in the interface, etc.*/}
            {/*</p>*/}
            <p>
              The two scores are used to calculate a final score and a probability of the interface being
              biologically relevant (1 being certainly biological, 0 certainly crystal contact). The call and the
              probability appear in the "Final" column. <font color="green">BIO</font> will mean that the probability
              is above 0.5 and <font color="red">XTAL</font> that the probability is below 0.5. This is the final
              prediction column and what you need to look at first. An estimated confidence level for the prediction is
              depicted with stars, golden star <StarFill className="star-excellent-conf"/> for high
              confidence, gray star <StarFill className="star-good-conf"/> for medium confidence, no
              star for low confidence.
            </p>
            <p>
              The other columns in the interface table correspond to a few important parameters describing the
              interfaces: the two chain codes of the partners (e.g. "A+B"), the Buried Surface Area upon interface
              formation (interface sorting is based on this value), the icon of the crystal operator used to generate
              the second partner of the interface
            </p>
            {/*<p>and the number of core residues on each side of the interface (e.g.*/}
            {/*  "4+5").*/}
            {/*</p>*/}
            <p>
              The operators are represented as icons to show at a glance what kind of crystallographic symmetry is
              present at the interface. The actual full algebraic operator (e.g. "-X+1,Y-1/2,-Z") can still be seen by
              hovering the mouse over the icon. The icons used for the operators are mostly the standard ones found in
              crystallographic tables: <img className="eppic-iframe-optype-img" src="public/images/optype_AU.png"
                                            alt="Identity operator"/> the identity operator (i.e. an interface in the
              asymmetric unit), <img
                className="eppic-iframe-optype-img" src="public/images/optype_XT.png" alt="Crystal translation"/> a
              crystal translation (integer) without rotation, <img className="eppic-iframe-optype-img"
                                                                   src="public/images/optype_FT.png"
                                                                   alt="Re-centering translation"/> a re-centering
              translation without
              rotation, <img className="eppic-iframe-optype-img" src="public/images/optype_2.png" alt="2-fold axis"/>
              a 2-fold axis, <img className="eppic-iframe-optype-img" src="public/images/optype_2S.png"
                                  alt="2-fold screw axis"/> a 2-fold screw axis, <img
                className="eppic-iframe-optype-img"
                src="public/images/optype_3.png" alt="3-fold axis"/> a 3-fold axis, <img
                className="eppic-iframe-optype-img" src="public/images/optype_3S.png" alt="3-fold screw axis"/> a
              3-fold screw axis, <img className="eppic-iframe-optype-img" src="public/images/optype_4.png"
                                      alt="4-fold axis"/> a 4-fold axis, <img className="eppic-iframe-optype-img"
                                                                              src="public/images/optype_4S.png"
                                                                              alt="4-fold screw axis"/> a 4-fold screw
              axis, <img
                className="eppic-iframe-optype-img" src="public/images/optype_6.png" alt="6-fold axis"/> a 6-fold
              axis, <img className="eppic-iframe-optype-img" src="public/images/optype_6S.png" alt="6-fold screw axis"/>
              a 6-fold screw axis.
            </p>
            <p>
              For the rare cases where a protein is crystallized in non-chiral space groups (e.g. racemic mixtures)
              there are additional operators: <img className="eppic-iframe-optype-img"
                                                   src="public/images/optype_-1.png" alt="Inversion centre"/> an
              inversion centre, <img
                className="eppic-iframe-optype-img" src="public/images/optype_-2.png" alt="Mirror plane"/> a mirror
              plane, <img className="eppic-iframe-optype-img" src="public/images/optype_GL.png" alt="Glide plane"/>
              a glide plane, <img className="eppic-iframe-optype-img" src="public/images/optype_-3.png"
                                  alt="Improper 3-fold axis"/> an improper 3-fold axis, <img
                className="eppic-iframe-optype-img"
                src="public/images/optype_-4.png" alt="Improper 4-fold axis"/> an improper 4-fold axis, <img
                className="eppic-iframe-optype-img" src="public/images/optype_-6.png" alt="Improper 6-fold axis"/> an
              improper 6-fold axis.
            </p>
            <p>
              Some of these operators lead to the formation of infinite interfaces if occurring between two
              crystallographically-related copies of the same molecule (e.g. A+A). This happens for both the pure
              translations and for any of the screw rotations and it is generally a very strong indication of a crystal
              contact. In those cases, we color the operator icon in red. The final call does not take that information
              into account, but this is very important for the enumeration of valid assemblies in the crystal shown in
              the assemblies table.
            </p>
          </div>
        </div>

        <h2 id="3D">Viewing interfaces and assemblies in 3D</h2>
        <div className="eppic-help-subtopic">

          {/* TODO have screenshots once we have core residues in sticks working*/}
          {/*<div className="eppic-help-subtopic-left-small">*/}
          {/*  <img*/}
          {/*      className="eppic-help-subtopic-image-nozoom"*/}
          {/*      src="resources/images/screenshots/interface-view-ngl.png"*/}
          {/*      alt="3D view of an interface as displayed by the NGL viewer"*/}
          {/*  />*/}
          {/*</div>*/}
          <div className="eppic-help-subtopic-right-big">
            <p>
              The thumbnails in the Assembly and Interface tables give a visual cartoon representation of the assemblies
              and interfaces. By clicking on them one gets an interactive 3D view with the&nbsp;
              <a href="https://github.com/molstar/molstar">Mol*</a> viewer. In the interface view the two protomers are
              represented as cartoons with interface residues also shown as sticks. Core residues from both protomers
              are shown in two different shades of red. The sequence entropy values for each residue are written as
              b-factors in the .cif file.
            </p>
            {/*TODO write something when we have a view (1D3D?) that can show the entropy in 3D*/}
            {/*<p>*/}
            {/*  Once in the Mol* window, by pressing "p" the user can get a surface representation of the subunits with*/}
            {/*  sequence conservation values from the Multiple Sequence Alignment mapped onto it in a color code: blue*/}
            {/*  colors indicate low entropies (high conservation) and colors toward yellow correspond to increasingly*/}
            {/*  higher entropies (lower conservation). Pressing "n" the surface representation toggles through the chains.*/}
            {/*  This representation is particularly useful in order to get a visual impression of the selection pressure*/}
            {/*  patterns on the surface of a given chain. Residues that could not be aligned (for instance because they*/}
            {/*  belong to an engineered tag) appear in red.*/}
            {/*</p>*/}
          </div>
        </div>

        <h2 id="lattice">The lattice graph</h2>
        <div className="eppic-help-subtopic">
          {/*<div className="eppic-help-subtopic-right-small">*/}
          {/*  <img*/}
          {/*      className="eppic-help-subtopic-image-right-nozoom"*/}
          {/*      src="resources/images/screenshots/lattice-graph-3d.png"*/}
          {/*      alt="3D view of the lattice graph"*/}
          {/*  />*/}
          {/*</div>*/}
          <div className="eppic-help-subtopic-left-big">
            <p>
              The assemblies are analysed through a graph representation of the chains and interfaces. This lattice
              graph is a periodic graph, with chains being the nodes and interfaces the edges. Visual tools to look at
              the graph are provided. They can be useful in understanding the crystal packing and the different
              possible assemblies that can be constructed with the given connectivity.
            </p>
            <ul>
              {/* TODO bring back once we have a 3D view of the lattice graph */}
              {/*<li>*/}
              {/*  <strong>3D lattice graph view with <a href="https://github.com/arose/ngl">NGL</a></strong>: the graph*/}
              {/*  is shown in 3D overlayed on top of the protein in semi-transparent cartoon representation. The nodes are*/}
              {/*  placed at the centroids of the chains. Different colors are assigned to different molecular entities and*/}
              {/*  to distinct interfaces. The view can be obtained by clicking on the "<strong>View Unit Cell</strong>" or*/}
              {/*  "<strong>View Assembly in Unit Cell</strong>" links in the information panel.*/}
              {/*</li>*/}
              <li>
                <strong>2D lattice graph view (visjs)</strong>: the graph is shown in a dynamic 2D layout. Different
                colors are assigned to different molecular entities and to distinct interfaces. This view can be
                obtained by clicking on any of the assembly diagram thumbnails in the assemblies table.
              </li>
            </ul>
          </div>
        </div>
        <h2>The sequences table</h2>
        <p>
          The header of the <b>Sequence Information</b> tab shows which version of the UniProt database version is
          used to find homologs for the EPPIC multiple sequence alignments. A new UniProt database release appears
          a few times a year containing more and more sequences. With more sequences we can obtain
          better predictions (see this <a
            href="http://www.biomedcentral.com/1471-2105/13/334/figure/F6" target="_blank" rel="noreferrer">figure</a>),
          thus the growth
          of the UniProt sequence database has quite an important effect on the accuracy of our method. We will try to
          maintain the results as up-to-date as possible and to update the PDB-wide precomputed results every month
          (for every UniProt update). In any case if you use our results <strong>it is important to quote the UniProt
          database version used</strong>.
        </p>
        <p>
          The sequences table provides information about the sequence
          homologs in the Multiple Sequence Alignment (MSA) used for entropy calculation.
          This information is given for all unique chains (protein polymer entities) in the
          structure. More details of the sequence of a particular homolog
          can be found by clicking the UniProt link.
        </p>
        <p>
          Note that for the MSA calculation sequences are clustered
          so that no pair of sequences are more similar than a certain threshold. The link in the right-most column
          enables you to download the MSA of all homolog sequences (FASTA format). You will need an
          alignment viewer like <a href="http://www.jalview.org/" target="_blank" rel="noreferrer">Jalview</a> to
          have it nicely displayed.
        </p>

        <h2 id="known-issues">Known issues</h2>
        <ul>
          <li>
            <b>MHC and antibody interfaces not correctly predicted</b>: due to the special nature of the MHC and
            antibodies sequences, the evolutionary criteria used by EPPIC do not hold for them. Thus the predictions
            for interfaces with at least one MHC or antibody molecule will often be incorrect.
          </li>
        </ul>

        <h2 id="job-identifiers">Job identifiers</h2>
        <p>
          Every new job run on the server is assigned a unique job identifier, a long alphanumerical string that is
          only known by the user that submits it and that is very hard to guess. It is recommended that you give an
          email address while submitting so that you receive the URL with the job identifier in your inbox. Otherwise
          you will have to bookmark it or keep a record of it yourself. It is always possible to retrieve the job by
          using the URL <code>https://www.eppic-web.org/assemblies/&lt;my_job_id&gt;</code>. Whether the job is still
          running or already done, the URL will show its current status and automatically display the final results
          whenever it is finished. To share the results of a job with colleagues just send them the corresponding URL.
          The jobs will be stored in our servers for 1 month and then deleted.
        </p>
        <p>
          The PDB-wide precomputed results can be accessed directly by using the permanent URLs:
        </p>
        <p>
          <code>https://www.eppic-web.org/assemblies/&lt;PDB_code&gt;</code>
        </p>

        <h2 id="funding">Funding</h2>
        <p>
          Funding to the project came initially from the{' '}
          <a href="https://www.psi.ch" target="_blank" rel="noreferrer">Paul Scherrer Institute</a> (2010-2014) and
          later from the{' '}
          <a href="http://www.snf.ch/" target="_blank" rel="noreferrer">Swiss National Science
            Foundation</a> (2013-2016). Since 2016
          the <a href="http://www.rcsb.org/pages/about-us/index" target="_blank" rel="noreferrer">RCSB Protein Data
          Bank</a> has
          supported the project and enabled its continuation. The RCSB PDB is funded by a grant (DBI-1338415) from
          the{' '}
          <a href="http://www.nsf.gov/" target="_blank" rel="noreferrer">National Science Foundation</a>, the{' '}
          <a href="http://www.nih.gov/" target="_blank" rel="noreferrer">National Institutes of Health</a>, and the{' '}
          <a href="http://science.energy.gov/" target="_blank" rel="noreferrer">US Department of Energy</a>.
        </p>
      </div>
      <Footer fixbottom={false}/>
    </div>
  );
};

export default HelpPage;