export const TAB_ASSEMBLIES = "assemblies";
export const TAB_INTERFACES = "interfaces";
export const TAB_SEQUENCE = "sequence";

export const INTERF_END_POINT =  "/rest/api/v3/job/interfaces/";

export const INTERF_RESIDUE_END_POINT = "/rest/api/v3/job/interfaceResidues/";

export const ASSEMBLIES_END_POINT = "/rest/api/v3/job/assemblies/";

export const ASSEMBLIES_DIAGRAM_END_POINT = "/rest/api/v3/job/assemblyDiagram/";

export const SEQUENCES_END_POINT = "/rest/api/v3/job/sequences/";

export const FILE_UPLOAD_END_POINT = "/rest/api/v3/submit/new";

export const FILE_STATUS_END_POINT = "/rest/api/v3/submit/status/";

export const PDBINFO_END_POINT = "/rest/api/v3/job/pdb/";

export const IMAGE_END_POINT = "/rest/api/v3/job/image/"

export const ASSEMBLY_CIF_END_POINT = "/rest/api/v3/job/assemblyCifFile/"
export const INTERFACE_CIF_END_POINT = "/rest/api/v3/job/interfaceCifFile/"

export const BIO_EXCELLENT_CUTOFF = 0.95

export const BIO_GOOD_CUTOFF = 0.8

export const XTAL_EXCELLENT_CUTOFF = 0.05

export const XTAL_GOOD_CUTOFF = 0.2

export const JobStatus = {
    // job status as returned by API status endpoint:
    // RUNNING, FINISHED, ERROR, STOPPED, NONEXISTING, QUEUING, WAITING
    RUNNING: "RUNNING",
    FINISHED: "FINISHED",
    ERROR: "ERROR",
    STOPPED: "STOPPED",
    NONEXISTING: "NONEXISTING",
    QUEUING: "QUEUING",
    WAITING: "WAITING"
}

export function getInterfCifUrl(eppicApiServerBaseUrl, pdbId, interfaceId) {
    return eppicApiServerBaseUrl + INTERFACE_CIF_END_POINT + pdbId + "/" + interfaceId;
}

export function getAssemblyCifUrl(eppicApiServerBaseUrl, pdbId, assemblyId) {
    return eppicApiServerBaseUrl + ASSEMBLY_CIF_END_POINT + pdbId + "/" + assemblyId;
}

export function getInterfImgUrl(eppicApiServerBaseUrl, jobId, interfId) {
    return eppicApiServerBaseUrl + IMAGE_END_POINT + jobId + "/interface/" + interfId;
}

export function getAssemblyImgUrl(eppicApiServerBaseUrl, jobId, assemblyId) {
    return eppicApiServerBaseUrl + IMAGE_END_POINT + jobId + "/assembly/" + assemblyId;
}
export function getAssemblyDiagramImgUrl(eppicApiServerBaseUrl, jobId, assemblyId) {
    return eppicApiServerBaseUrl + IMAGE_END_POINT + jobId + "/diagram/" + assemblyId;
}

export function getOpTypeImgUrl(opType, isInfinite) {
    const optypeUrl = "/public/images/optype_";
    const optypeSuffix = ".png";
    let opTypeFull = opType;
    if (isInfinite) {
        opTypeFull = opType + "_inf";
    }
    return optypeUrl + opTypeFull + optypeSuffix;
}

export function transformInterfData(data) {
    let tData = [];

    data.forEach(item => {
        let scoresPerMethod = {};
        item.interfaceScores.forEach(scoreObj => {
            scoresPerMethod[scoreObj.method] = scoreObj;
        });
        for(var method in scoresPerMethod) {
            item[method] = scoresPerMethod[method];
        }
        // for user jobs tha ran without evol scoring, this fills in the missing data with empty fields and avoids null pointers
        if (!("eppic-cs" in item)) {
            item["eppic-cs"] = {"score": 0.0, "callName": "?", "callReason": ""};
        }
        if (!("eppic" in item)) {
            item["eppic"] = {"score": 0.0, "callName": "?", "callReason": ""};
        }
        tData.push(item);
        });

    tData.forEach(item => {
        delete item.interfaceScores;
    });

    return tData;
}

export function getChainIdsFromInterfObj(interf) {
    let chain2CompId = interf.chain2;
    if (interf.chain1 === interf.chain2) {
        chain2CompId = interf.chain2 + "_" + interf.operatorId;
    }
    return [interf.chain1, chain2CompId];
}

export function transformAssembliesData(data) {
    let tData = [];

    data.forEach(item => {
        let scoresPerMethod = {};

        if (item.id > 0) { // we skip the special 0 assembly (unit cell assembly) and the special -1 assembly (PDB only, not found by eppic)
            item.assemblyScores.forEach(scoreObj => {
                scoresPerMethod[scoreObj.method] = scoreObj;
            });
            for(var method in scoresPerMethod) {
                item[method] = scoresPerMethod[method];
            }

            tData.push(item);
            let sizes = [];
            let syms = [];
            let stos = [];
            item.assemblyContents.forEach(cont => {
                sizes.push(cont.mmSize);
                syms.push(cont.symmetry);
                stos.push(cont.stoichiometry);
            });
            item["mmSizes"] = sizes.join(", ");
            item["symmetries"] = syms.join(", ");
            item["stoichiometries"] = stos.join(", ");
        }
    });

    tData.forEach(item => {
        delete item.assemblyScores;
    });

    // sort by score so that display is first most likely assembly
    tData.sort((a, b) => (a.eppic.score > b.eppic.score ? -1 : 1));
    return tData;
}

export function seqDataToMsaFasta(sequences, repChain) {
    let seqObj;
    sequences.forEach(sequence => {
        if (sequence.repChain === repChain) {
            seqObj = sequence;
        }
    });
    if (seqObj === null) {
        console.error("Could not find sequence data for repChain " + repChain);
        return null;
    }
    let fastaStr = ">" + seqObj.refUniProtId + "_" + seqObj.refUniProtStart + "-" + seqObj.refUniProtEnd + "\n" + seqObj.msaAlignedSeq + "\n";
    seqObj.homologs.forEach(hom => {
       fastaStr += ">" +hom.uniProtId + "_" + hom.subjectStart + "-" + hom.subjectEnd + "\n" + hom.alignedSeq + "\n";
    });
    return fastaStr;
}

export function getColorFromEppicScoreObject(eppicScoreObject) {
    if (eppicScoreObject.callName === null) {
        return 'black';
    }
    return getColorFromCallName(eppicScoreObject.callName);
}

function getColorFromCallName(callName) {
    if (callName === undefined) {
        return 'black';
    }
    // comparing case insensitive
    if (callName.localeCompare('bio', undefined, {sensitivity: 'accent'}) === 0) {
        return 'green';
    } else if (callName.localeCompare('xtal', undefined, {sensitivity: 'accent'}) === 0) {
        return 'red';
    } else {
        return 'black';
    }
}

export function getStoForDisplay(stoString) {
    let finalStr = "";
    if (stoString.includes(" ")) {
        stoString.split(' ').forEach(str => {
            finalStr += stoWithBracketsToHtmlSup(str) + " ";
        })
    } else if (stoString.includes(",")) {
        stoString.split(',').forEach(str => {
            finalStr += stoWithBracketsToHtmlSup(str) + ",";
        })
    } else {
        return stoWithBracketsToHtmlSup(stoString);
    }
    return finalStr;
}

function stoWithBracketsToHtmlSup(oneSto) {
    let tokens = oneSto.split('(');
    let finalStr = tokens[0];
    if (tokens[1] !== undefined && tokens[1] !== null) {
        finalStr += "<sub>" + tokens[1].replace(')', '') + "</sub>";
    }
    return finalStr;
}

export function getUniprotUrl(uniprotId) {
    return "https://www.uniprot.org/uniprotkb/"+uniprotId+"/entry";
}

export function isUserJobId(id) {
    // note the backend implements random jobId generation by UUID.randomUUID() and that's always 36 chars, including 4 hyphens:
    // https://stackoverflow.com/questions/45388021/java-util-uuid-randomuuid-tostring-length
    if (id.length === 36) {
        const hyphenCount = (id.match(/-/g) || []).length;
        if (hyphenCount === 4) {
            return true;
        }
    }
    return false;
}

export async function checkStatus(eppicApiServerBaseUrl, jobId) {
    // note that before I wrote this function in promise style, i.e. blah.then().then(), but somehow the caller was
    // getting an undefined param. Probably I was doing something wrong
    const response = await fetch(eppicApiServerBaseUrl + FILE_STATUS_END_POINT + jobId);
    if (!response.ok) {
        throw new Error("Could not fetch status for job id " + jobId + ". Response was " + response.status);
    }
    const data = await response.json();
    console.log(`Status for job ${jobId} is ${data.status.toString()}`);
    const serverJobStatus = data.status.toString();
    switch (serverJobStatus) {
        case JobStatus.FINISHED:
            return JobStatus.FINISHED;
        case JobStatus.ERROR:
            return JobStatus.ERROR;
        case JobStatus.RUNNING:
            return JobStatus.RUNNING;
        case JobStatus.WAITING:
            return JobStatus.WAITING;
        case JobStatus.QUEUING:
            return JobStatus.QUEUING;
        case JobStatus.NONEXISTING:
            return JobStatus.NONEXISTING;
        case JobStatus.STOPPED:
            return JobStatus.STOPPED;
        default:
            throw new Error("Unexpected status in server for job id " + jobId + ". Status was " + serverJobStatus);
    }
}

export async function isJobProcessing(eppicApiServerBaseUrl, jobId) {
    const jobStatus = await checkStatus(eppicApiServerBaseUrl, jobId);
    return isJobStatusProcessing(jobStatus);
}

export function isJobInLoadingState(jobIds, jobId) {
    const jobIdObj = jobIds.find(j => j.id === jobId);
    if (jobIdObj) {
        return isJobStatusProcessing(jobIdObj.jobStatus);
    }
    return false;
}

export function isJobStatusProcessing(jobStatus) {
    return jobStatus === JobStatus.RUNNING || jobStatus === JobStatus.QUEUING || jobStatus === JobStatus.WAITING;
}
